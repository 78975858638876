import { defineStore } from 'pinia'

export const useCookieStore = defineStore('cookie', {
  state: () => ({ 
    cookieConsent: false,
    declined: false
  }),
  getters: {
    consentGiven: (state) => (state.cookieConsent || localStorage.getItem('cookieConsent') != null),
    allowUsingApp: (state) =>(state.cookieConsent || localStorage.getItem('cookieConsent') != null || state.declined)
  },
  actions: {
    setConsent() {
      this.cookieConsent = true
      localStorage.setItem('cookieConsent', JSON.stringify(true))
    },
    setDeclined () {
      this.declined = true
    }
  }
})
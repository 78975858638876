<template>
  <div id="map"></div>
</template>

<script>
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import sigiStatue from "@/assets/imgs/statue_sigi.svg";
import sigiSchatten from "@/assets/imgs/sigi_schatten.svg";

export default {
  name: "Map",
  data() {
    return {
      map: null
    };
  },
  mounted() {
    this.map = L.map('map', {
      center: [48.1365382, 11.5736743],
      zoom: 18,
      scrollWheelZoom: false,
      detectRetina: true,
      dragging:false,
      touchZoom: true
  });
    
    L.tileLayer("https://{s}.tile.osm.org/{z}/{x}/{y}.png", {
      attribution:'&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(this.map);
    var LeafIcon = L.Icon.extend({
    options: {
       iconSize:     [50, 120],
       shadowSize:   [120, 100],
       iconAnchor:   [22, 110],
       shadowAnchor: [12, 78],
       popupAnchor:  [-3, -100]
    }
});
    var greenIcon = new LeafIcon({
    iconUrl: sigiStatue,
    shadowUrl: sigiSchatten
})

    var marker = L.marker([48.1365382, 11.5736743], {icon: greenIcon}).addTo(this.map);
    marker.bindPopup("<a href='https://www.google.de/maps/dir//Sigi+Sommer+Monument,+Rosenstra%C3%9Fe+6,+80331+M%C3%BCnchen/@48.1353433,11.5672528,17z/data=!3m1!5s0x479e75f53095b003:0xf3a77157eebb3050!4m9!4m8!1m0!1m5!1m1!1s0x479e75f53a3fb339:0x59e53c15780e4043!2m2!1d11.5737829!2d48.1365018!3e1?entry=ttu' target='_blank' rel='noopener noreferrer'><span class='highlight'>Sigi Sommer Statue</span></a>"
  ,{closeButton: false
}).openPopup();

  },
  beforeDestroy() {
    if (this.map) {
      this.map.remove();
    }
  }
};
</script>
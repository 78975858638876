import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

import './assets/main.css'
import { createPinia } from 'pinia'
import VueMatomo from 'vue-matomo'

const app = createApp(App)
const pinia = createPinia()
app.use(pinia)
app.use(VueMatomo, {
  // Configure your matomo server and site by providing
  host: 'https://matomo.kollektiv17.de',
  siteId: 3,
  requireCookieConsent: true
})
app.use(router)

app.mount('#app')
window._paq.push(['trackPageView']);
window._paq.push(['setCookieSameSite', 'None']);
<template>
	<div class="dialogContainer">
			<Splide ref="mySplide" :options="{ arrows: false, drag:allowUsingApp  }">
				<SplideSlide>
					<div class="animatedWelcome">
						<div class="innerContainer">
							<h1>Griaß di!</h1>
							<div class="spacerS"></div>
							<div class="contentContainer">
								<p>
									Schön, dass du zu mir gefunden hast. Ich bin der <strong>Spaziergänger Sigi Sommer</strong>.
								</p>
								<p>
								Mit deiner Hilfe kann ich endlich wieder am Rindermarkt in meinem geliebten München stehen.
								</p>
							</div>
							<img :src="statue" alt="Statue Sigi Sommer" class="statue" />
							<img :src="cloud" alt="Wolke" class="cloud cloud1" />
							<img :src="cloud2" alt="Wolke" class="cloud cloud2" />
							<button class="btn btn-primary btn-lg" @click="showSlidePage(1)" :disabled="!allowUsingApp">Weiter</button>
						</div>
					</div>
				</SplideSlide>
				<SplideSlide>
					<div class="mainContainer">
						<div class="mainContainerHeader">
							<h1>Wer ist Sigi?</h1>
							<img :src="cloud" alt="Wolke" class="cloud cloud1" />
							<img :src="statue" alt="Statue Sigi Sommer" class="statue" />
						</div>
						<div class="spacerM"></div>
						<div class="contentContainer">
							<p>
							<img :src="statueReal" alt="Statue Sigi Sommer" class="statueReal" />
							So stand ich als Statue 25 Jahre lang mitten unter den Leuten, bis ich auf einmal verschwunden war.
							</p>
							<p>
								Und vor meiner Zeit als Statue war ich tatsächlich oft hier als Kolumnist, Autor oder einfach nur Spaziergänger unterwegs.
							</p>
						</div>
						<button class="btn btn-primary btn-lg" @click="showSlidePage(2)">Weiter</button>
					</div>
				</SplideSlide>
				<SplideSlide>
					<div class="mainContainer">
						<div class="mainContainerHeader">
							<h1>Wo ist Sigi?</h1>
							<img :src="cloud" alt="Wolke" class="cloud cloud1" />
							<img :src="statue" alt="Statue Sigi Sommer" class="statue" />
						</div>
						<Map />
						<button style="position: absolute; bottom: 0px; z-index: 1000;" class="btn btn-primary btn-lg" @click="goToXrPage()">Sprich mit Sigi</button>
					</div>
				</SplideSlide>
			</Splide>
	</div>
	<div class="indicatorContainer">
		<span class="active"></span>
		<span></span>
	</div>
	<Footer />
</template>

<script>
import { defineComponent, ref } from 'vue';
import Footer from "../components/Footer.vue";
import statue from "@/assets/imgs/statue_sigi.svg";
import statueReal from "@/assets/imgs/statue_sigi_real.jpg";
import cloud from "@/assets/imgs/cloud.svg";
import cloud2 from "@/assets/imgs/cloud2.svg";
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/vue-splide/css';
import standort from "@/assets/imgs/karte-sigi.svg";
import * as Utils from '@/helper/utils'
import { mapState } from 'pinia'
import { useCookieStore } from '../stores/cookiestore'
import Map from "@/components/Map.vue";

export default defineComponent( {
	setup() {
		const mySplide = ref();
		return {
			statue,
			statueReal,
			cloud,
			cloud2,
			standort,
			mySplide
		};
	},
	components: {
    Footer,
		Splide,
    SplideSlide,
		Map
  },
	data () {
		return {
			isMobile: true,
      splideSite: 0
		}
	},
	created() {
		this.isMobile = Utils.mobileAndTabletCheck() 
	},
	methods: {
    goToXrPage() {
      if (this.isAndroidFirefox) {
        window.location = Utils.getUrl('ar', this.consentGiven)
      } else { 
        this.$router.push('/xr-view')
      }
    },
    showSlidePage(number){
      this.mySplide.go(number)
      var trackPagetitle = { 0: 'Startseite', 1: 'Wer-Seite', 2: 'Wo-Seite'}
      
      window._paq.push(['trackPageView', trackPagetitle[number]])
    }
  },
  computed: {
    isAndroidFirefox() {
      return /Android.+Firefox\//.test(navigator.userAgent)
    },
    ...mapState(useCookieStore, ['allowUsingApp', 'consentGiven']),
  }
});
</script>
<template>
  <header>
    <button @click="$router.push('/')" class="backButton"></button>
    <h1>{{title}}</h1>
  </header>
</template>

<script>
  export default {
    name: 'Header',
    props: {
      title: {
        type: String,
        required: true
      }
    }
  }
</script>../

<style scoped>
  header {
    background-color: var(--mimo-color-sky-blue);
    position: fixed;
    width: 100%;
    top: 0px;
    left: 0px;
    height:  var(--mimo-header-height);
    z-index: 100;
  }
  h1 {
    font-size: 24px;
    padding: 0;
    text-align: center;
    margin: 0;
    line-height: 70px
  }
  @media (min-width: 768px) {
    h1 {
    font-size: 36px;
  }
  }
</style>

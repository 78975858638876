<template>
  <Header title="Impressum" />
  <div class="subpageContainter">
    <h2>ANGABEN GEMÄSS § 5 TM</h2>
    <p>
      Kollektiv17 – Jonas, Pfilf &amp; Schmitt GbR<br>Herzog-Wilhelm-Str. 27<br>80331 München
    </p>
    <p><strong>Vertreten durch:</strong><br>
      Thorsten Jonas<br>
      Wilfried Pfilf<br>
      Simon Schmitt
    </p>
    <h2>KONTAKT</h2>
    <p>E-Mail: info@kollektiv17.de</p>
    <h2>UMSATZSTEUER-ID</h2>
    <p>Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz:<br>DE323795167</p>
    <h2>ANGABEN ZUR BERUFSHAFTPFLICHTVERSICHERUNG</h2>
    <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
      teilzunehmen.</p>
    <h3>HAFTUNG FÜR INHALTE</h3>
    <p>Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen
      Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet,
      übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf
      eine rechtswidrige Tätigkeit hinweisen.</p>
    <p>
      Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen
      bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer
      konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese
      Inhalte umgehend entfernen.
    </p>
    <h3>HAFTUNG FÜR LINKS</h3>
    <p>
      Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss haben.
      Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten
      Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden
      zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt
      der Verlinkung nicht erkennbar.
    </p>
    <p>
      Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer
      Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend
      entfernen.
    </p>
    <h3>URHEBERRECHT</h3>
    <p>
      Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen
      Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen
      des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und
      Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
    </p>
    <p>
      Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter
      beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
      Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von
      Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
    </p>
  </div>
  <Footer />
</template>

<script>

import Footer from "../components/Footer.vue";
import Header from "../components/Header.vue";

export default {
  name: 'Imprint',
  components: {
    Footer,
    Header
  },
  created () {
    window._paq.push(['trackPageView', 'Impressum'])
  }
};
</script>
<style scoped></style>
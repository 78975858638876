<template>
	<div class="xr-iframe">
		<iframe
			id="sigi-ar-iframe"
			style="border: 0; width: 100%; height: 100%"
			allow="camera;gyroscope;accelerometer;magnetometer;xr-spatial-tracking;"
			:src="url">
		</iframe>
	</div> 
</template>

<script>
import * as Utils from '@/helper/utils'
import { mapState } from 'pinia'
import { useCookieStore } from '../stores/cookiestore'

export default {
  name: 'XRView',
  components: {},
	data () {
		return {
      url: Utils.getUrl('vr',  this.consentGiven)
    }
	},
	methods: {},
	beforeCreate() {},
	computed: {
    url() {
      return Utils.mobileAndTabletCheck() ? Utils.getUrl('ar', this.consentGiven) : Utils.getUrl('vr',  this.consentGiven)
    },
    ...mapState(useCookieStore, ['consentGiven']),
  }
}
</script>
<style scoped>
 h3 {
  color: white;
 }
div.xr-iframe {
	height: 100svh;
}

</style>
<template>
  <div class="banner">
    <p>
      Wir respektieren deine Privatsphäre. Wir nutzen technische und funktionale Cookies, damit dein Besuch auf wo-ist-sigi.de funktioniert.
    </p>
    <p>
      <a href="" @click="$router.push('/legal')">Datenschutzerklärung ansehen</a>
    </p>
    <div class="cookie-buttons">
      <button class="btn btn-primary btn-lg cookie-button" @click="acceptCookies">Cookies akzeptieren</button>
      <button class="btn btn-secondary btn-lg cookie-button" @click="declineCookies">Optionale Cookies ablehnen</button>
    </div>
 
  </div>
</template>

<script>
import { mapActions } from 'pinia'
import { useCookieStore } from '../stores/cookiestore'
export default {
  name: "CookieBanner",
  mounted () {
  },
  methods: {
    acceptCookies() {
      this.setConsent()
      window._paq.push(['rememberCookieConsentGiven'])
    },
    declineCookies() {
      this.setDeclined()
    },
    ...mapActions(useCookieStore, ['setConsent','setDeclined']),
  }

};
</script>
<style scoped>
.banner {
  background-color: rgba(40, 68, 85, 0.95);
  padding: 20px;
  text-align: center;
  position: fixed;
  bottom: 0px;
  width: 100%;
  color: var(--mimo-color-white);
  z-index: 100;
}
.banner p {
  margin: 0px;
}
.cookie-button {
  width: 300px;
  font-size: 16px;
  margin-bottom: 12px;
}
@media (min-width: 768px) {
  .cookie-button {
    width: 300px;
    margin: var(--mimo-spacing-m);
  }
}
</style>
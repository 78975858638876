import { createRouter, createWebHistory } from 'vue-router'
import XRView from '../views/XRView.vue'
import HomeView from '../views/HomeView.vue'
import MapView from '../views/MapView.vue'
import LegalView from '../views/LegalView.vue'
import ImprintView from '../views/ImprintView.vue'

const router = createRouter({
	//start at beginning of page everytime routed
	scrollBehavior: () => ({ x: 0, y: 0 }),
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
		{
			path: '/',
			name: 'home',
			component: HomeView,
		},
    {
      path: '/xr-view',
      name: 'xrview',
      component: XRView
    },
    {
      path: '/map',
      name: 'map',
      component: MapView
    },
    {
      path: '/legal',
      name: 'legal',
      component: LegalView
    },
    {
      path: '/imprint',
      name: 'imprint',
      component: ImprintView
    },
  ]
})

export default router

<template>
  <footer>
    <a target="blank" href="https://www.mission-monument.de/" title="Link zur Webseite von Mission Monument"><img :src="mimoLogo" alt="Logo Mission Monument" /></a>
    <div>
      <a href="" @click="$router.push('/imprint')">Impressum</a>
      <a href="" @click="$router.push('/legal')">Datenschutz</a>
    </div>
    
  </footer>
</template>

<script>
  import mimoLogo from "@/assets/imgs/powered-by-mission-monument.svg";
  export default {
    name: 'Footer',
    setup() {
			return {
				mimoLogo
			};
		}
  }
</script>
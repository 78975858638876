<template>
  <router-view />
  <CookieBanner v-if="!allowUsingApp" />
  <!--<Footer />-->
</template>

<script>
// import Footer from "@/components/Footer.vue";
import CookieBanner from './components/CookieBanner.vue'
import { mapState } from 'pinia'
import { useCookieStore } from './stores/cookiestore'

export default {
  name: 'App',
  computed: {
    ...mapState(useCookieStore, ['allowUsingApp']),
  },
  components: {
    CookieBanner
  }
};
</script>

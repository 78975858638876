<template>
  <Header title="Hier findest du mich" />
  <div class="aboutPage">
    <div class="mapContainer position-relative" style="top:var(--mimo-header-height)">
      <a href="https://www.google.de/maps/dir//Sigi+Sommer+Monument,+Rosenstra%C3%9Fe+6,+80331+M%C3%BCnchen/@48.1353433,11.5672528,17z/data=!3m1!5s0x479e75f53095b003:0xf3a77157eebb3050!4m9!4m8!1m0!1m5!1m1!1s0x479e75f53a3fb339:0x59e53c15780e4043!2m2!1d11.5737829!2d48.1365018!3e1?entry=ttu" class="btn btn-primary btn-lg position-absolute googleMapsLink">Navigation mit Google Maps</a>
      <Map />
    </div>
  </div>
</template>

<script>

import Header from "@/components/Header.vue";
import Map from "@/components/Map.vue";

export default {
	name: 'MapView',
  components: {
    Header,
    Map
  }
};
</script>
<style scoped>
a.googleMapsLink {
  z-index: 500;
  position: absolute;
  bottom: 0px;
  left: var(--mimo-spacing-s);
  width: calc(100% - 2 * var(--mimo-spacing-s));
}
</style>